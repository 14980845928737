import React from "react";
import useQueryWaIframeParams from "../../hooks/useQueryWaIframeParams";
import Layout from "../../layouts/LayoutIframe";
import updateTariff from "../../components/iframe/wa-oa-addon-paywall/updateTariff";
import Tariff from "../../components/iframe/wa-oa-addon-paywall/Tariff";
import "../../components/iframe/wa-oa-addon-paywall/index.scss";

const WaOaAddonPaywall = () => {
  const { getParams, getLangPath } = useQueryWaIframeParams();
  const { monthly, annual, lifetime } = updateTariff(getLangPath, getParams);
  return (
    <Layout withTagManager={false} className="PgIWOAP" turnOnEventView>
      <p className="PgIWOAP__title">Pricing</p>
      <div className="PgIWOAP-PL">
        <Tariff data={monthly} />
        <Tariff data={annual} />
        <Tariff data={lifetime} />
      </div>
    </Layout>
  );
};

export default WaOaAddonPaywall;

const updateTariff = (getLangPath, getParams) => ({
  monthly: {
    name: "Monthly",
    pay: {
      price: 35,
      cents: 99,
      period: "month",
    },
    buyNow: {
      path: `${getLangPath}/checkout/?offerId=addon_new_monthly${getParams}`,
    },
  },
  annual: {
    name: "Annual",
    pay: {
      discount: 431.88,
      price: 199,
      cents: 99,
      period: "year",
    },
    buyNow: {
      path: `${getLangPath}/checkout/?offerId=addon_new_annual${getParams}`,
    },
  },
  lifetime: {
    name: "Lifetime",
    pay: {
      discount: 1799,
      price: 999,
      cents: 99,
      period: "lifetime",
    },
    buyNow: {
      path: `${getLangPath}/checkout/?offerId=addon_new_lifetime${getParams}`,
    },
  },
});
export default updateTariff;
